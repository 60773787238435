#header {
    padding: 1em;
}

#outerBox {
    padding: 1em;
    margin-top: 1em;
}

#tabcontainer {
    background-color: white;
    border-color: #797979;
    border-style: solid;
    border-width: 1px;
}

#tabs {
    text-align: center;
    max-height: 25px;
}

