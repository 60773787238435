#main-menu-button {
  align-self: center;
  margin-top: auto;
  width: 100%;
}

#button-stack-div {
  padding-top: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  height: 75vh;
  display: flex;
  flex-direction: column;
}
