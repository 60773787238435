span.MuiCheckbox-root {
  padding: 0px !important;
}

#printers {
  font-size: medium;
  border-color: black;
  border-radius: 4px;
  padding: 5px;
}

.batch-row {
  padding-bottom: 75px;
}
