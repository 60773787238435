img#login-header {
  width: 100%;
}

img#rms-logo {
  width: 100%;
}

select,
input {
  width: 100%;
  font-size: medium;
  background-color: white;
}

input#password-input {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0em;
}

#login-name {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

#store-number {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

#image-grid {
  margin-bottom: 0em;
}

#form-grid {
  margin-top: 0;
  margin-left: 0;
  padding-right: 1em;
}

#button-grid {
  margin-top: 0.1em;
  padding-left: 1em;
  padding-right: 1em;
}

select:required:invalid {
  color: #767676;
}

option[value=''][disabled] {
  display: none;
}

option {
  color: black;
}

select:disabled {
  background-color: rgb(233,233,233)
}