#confirm-button-container {
    align-items: center;
    justify-content: center; 
    margin-bottom: 2vh;
}

#title-and-list-container {
    display:flex;  
    flex-direction: column;
    align-items: center;
    height: 60vh;
}

#dialog-title {
    margin-top: 2vh;
    width: 75vw;
    outline: solid;
    border-radius: 2px;
    padding: 0px;
}

#modalStack {
    display: flex; 
    justify-content: space-between;
    height: 75vh;
}

#natural {
    outline: solid black 1px;
    padding: 15px;
    margin-top: 1px;
    justify-content: center;
    width: 75vw;
    font-weight: bold;
}

#confirm-button {
    font-weight: 600;
    font-size: medium;
}

#list-container {
    height: 100%;

}

#errorText {
    padding: 10px;
    color: red;
    text-align: center;
    font-size: 1.5em;
}
