#user-container {
  padding-left: 1em;
  padding-right: 1em;
}

#main-grid {
  padding-top: 1.5em;
}
#add-button {
  padding-bottom: 2px;
}
